import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'

import * as actionCreators from '../store/actions'
import EssentialModePreviewModal from '../components/essential_mode/previewModal'

export class PreviewEssentialComparisonPage extends React.Component {
  render() {
    return (
      <Layout hideHeader>
        <div
          style={{
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src="/essentialMode/comparison.jpeg"
            alt=""
          />
          <EssentialModePreviewModal />
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    isLogged: state.authentication.authProcess.isLogged,
  }
}

export default connect(mapStateToProps, actionCreators)(PreviewEssentialComparisonPage)
