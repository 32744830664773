import React, { Component } from 'react'

class EssentialModePreviewModal extends Component {
  render() {
    function handleCancel() {
      if (typeof window !== 'undefined') {
        // window.history.back()
        window.history.go(-1)
      }
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#1e90ff',
            borderRadius: 8,
            boxShadow: '0 3px 7px -3px rgb(0 0 0)',
            color: 'white',
            position: 'relative',
            fontWeight: 'normal',
          }}
        >
          <div style={{ position: 'absolute', left: '-30px', top: '-25px' }}>
            <img src="/laptop.png" alt="" width="300" height="315" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '34px 34px 34px 304px',
              height: '200px',
              maxWidth: 318,
            }}
          >
            <div>
              <h1 style={{ fontSize: 24, margin: '0 0 10px 0', fontWeight: 'normal' }}>Do you want to see more?</h1>
              <p style={{ margin: '0 0 10px 0', letterSpacing: '.5px' }}>
                Want more features: comparison board, inter-raking, auto-report generator…
              </p>
            </div>

            <div style={{ display: 'flex' }}>
              <button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: '#0081FF',
                  border: 0,
                  borderRadius: 8,
                  height: 50,
                  display: 'flex',
                  color: 'white',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  letterSpacing: '.5px',
                  fontSize: 17,
                  justifyContent: 'center',
                  marginRight: 15,
                  paddingLeft: 15,
                  paddingRight: 15,
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>
              <a
                style={{
                  color: '#1F83E3',
                  backgroundColor: 'white',
                  textDecoration: 'none',
                  borderRadius: 8,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  letterSpacing: '.5px',
                  fontSize: 17,
                  justifyContent: 'center',
                  flexGrow: 1,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
                href="mailto:support@valueable.com"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EssentialModePreviewModal
